import throttle from 'lodash.throttle';

const phoneLandscapeViewportWidth = 512;
const tabletLandscapeViewportWidth = 992;

export default {
    data() {
        return {
            imageContainerWidth: 0,
            appElement: null,
            imgElement: null
        };
    },
    mounted() {
        if (document.body.classList.contains('middle-theme')) {
            return;
        }

        this.appElement = document.getElementById('app');
        this.imgElement = this.$el.querySelector('img');
        window.addEventListener('resize', throttle(this.updateLogoCalc, 700));
    },
    methods: {
        updateLogoCalc() {
            const appWidth = this.appElement.clientWidth;
            const height = (() => {
                if (appWidth <= phoneLandscapeViewportWidth) {
                    return 30;
                }
                if (appWidth <= tabletLandscapeViewportWidth) {
                    return 45;
                }
                return 92;
            })();
            const imgWidth = this.imgElement.naturalWidth;
            const imgHeight = this.imgElement.naturalHeight;
            const imgWidthCalculated = imgWidth / imgHeight * height;

            this.imageContainerWidth = { 'min-width': `${imgWidthCalculated}px` };
        }
    }
};