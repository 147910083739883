export default {
    props: {
        flavor: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            opened: false
        };
    },
    mounted() {
        if (this.flavor === 'link') {
            this.$el.addEventListener(
                'click', () => {
                    window.location = '/unverbindliche-Reservierung';
                }, { once: true }
            );
        }
    },
    methods: {
        toggleOpen() {
            this.opened = !this.opened;
        }
    }
};