var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-item" },
    [
      _vm._t("default", null, {
        isHidden: _vm.isHidden,
        messageButton: _vm.messageButton,
        onShowServiceNameButtonClick: _vm.onShowServiceNameButtonClick,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }