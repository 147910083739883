var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "swiper",
    {
      attrs: {
        "wrapper-class-name": "swiper-offers-middle",
        "swiper-class-name": "swiper-offers-middle__container",
        options: _vm.swiperOptions,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }