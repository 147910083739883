<template>
    <swiper
        wrapper-class-name="swiper-about"
        swiper-class-name="swiper-about__container"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>

import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    data() {
        return {
            swiperOptions: {
                autoHeight: true,
                slidesPerView: 1
            }
        };
    }
};
</script>

<style lang="scss">
    .swiper-about {
        position: relative;
        width: 100%;
        max-width: 1140px;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        &__image {
            display: block;
            max-width: 100%;
        }
    }
</style>