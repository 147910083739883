import { eventHub, EVENT_NAMES } from '../../services/eventService';

export default {
    data() {
        return {
            showMainNavi: false
        };
    },
    methods: {
        onShowMainNaviButtonClick() {
            eventHub.$emit(EVENT_NAMES.MODAL_OPEN, { disableBodyScrollOnMobile: true });
            this.showMainNavi = true;
        },
        onCloseMainNaviButtonClick() {
            eventHub.$emit(EVENT_NAMES.MODAL_CLOSE, {});
            this.showMainNavi = false;
        }
    }
};