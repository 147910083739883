var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticStyle: { "max-width": "100%" },
    attrs: {
      width: "757",
      height: "424",
      src: _vm.youtubeLink,
      frameborder: "0",
      allow:
        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }