var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "" + _vm.wrapperClassName + (_vm.isDisabled ? " disabled" : "") },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("div", {
        class:
          _vm.generatedPaginationClass +
          " swiper-pagination " +
          _vm.paginationClass,
      }),
      _vm._v(" "),
      _c("neo-icon", {
        class:
          _vm.arrowNextClass +
          " swiper-button swiper-button--next swiper-button__icon custom-color-b",
        attrs: { name: "arrow-right" },
      }),
      _vm._v(" "),
      _c("neo-icon", {
        class:
          _vm.arrowPrevClass +
          " swiper-button swiper-button--prev swiper-button__icon custom-color-b",
        attrs: { name: "arrow-left" },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }