export default {
    props: {
        searchQueryMobile: {
            // Handle request string for search query
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        isInInfobox: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            showPharmacySearchForm: false
        };
    },
    methods: {
        onTeaserClick () {
            this.showPharmacySearchForm = !this.showPharmacySearchForm;
        }
    }
};