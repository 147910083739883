var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "swiper",
    {
      attrs: {
        "wrapper-class-name": "swiper-magazine-middle",
        "swiper-class-name": "swiper-magazine-middle__container",
        options: _vm.swiperOptions,
      },
    },
    [
      _vm._t("default", null, {
        med: _vm.med,
        sr: _vm.sr,
        au: _vm.au,
        buf: _vm.buf,
        dr: _vm.dr,
        preview: _vm.preview,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }