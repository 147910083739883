import NeoIframe from './iframe.vue';

export default {
    data() {
        return {
            consentGiven: false
        };
    },
    methods: {
        showVideo() {
            this.consentGiven = true;
        }
    },
    components: {
        NeoIframe
    }
};