import { render, staticRenderFns } from "./swiperMagazine.vue?vue&type=template&id=4f12ede2&"
import script from "./swiperMagazine.vue?vue&type=script&lang=js&"
export * from "./swiperMagazine.vue?vue&type=script&lang=js&"
import style0 from "./swiperMagazine.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f12ede2')) {
      api.createRecord('4f12ede2', component.options)
    } else {
      api.reload('4f12ede2', component.options)
    }
    module.hot.accept("./swiperMagazine.vue?vue&type=template&id=4f12ede2&", function () {
      api.rerender('4f12ede2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/swiperMagazine/swiperMagazine.vue"
export default component.exports