import neoHeader from './header/header.vue';
import neoFooter from './footer/footer.vue';
import neoConsentManagement from './consentManagement/consentManagement.vue';
import neoOffers from './offers/offers.vue';
import neoOfferItem from './offers/offerItem/offerItem.vue';
import neoOfferModal from './offers/offerModal/offerModal.vue';
import neoOfferTeaser from './home/offerTeaser/offerTeaser.vue';
import neoContact from './contact/contact.vue';
import neoMainNavi from './header/mainNavi/mainNavi.vue';
import neoOpeningHours from './header/openingHours/openingHours.vue';
import neoLogo from './header/logo/logo.vue';
import neoMetaNavi from './header/metaNavi/metaNavi.vue';
import neoContactForm from './contact/contactForm/contactForm.vue';
import neoContactData from './contact/contactData/contactData.vue';
import neoContactMap from './contact/contactMap/contactMap.vue';
import neoNaviTeaser from './header/naviTeaser/naviTeaser.vue';
import neoPharmacySearch from './pharmacySearch/pharmacySearch.vue';
import neoPharmacySearchTeaser from './home/pharmacySearchTeaser/pharmacySearchTeaser.vue';
import neoPharmacySearchTeaserMiddle from './home/pharmacySearchTeaserMiddle/pharmacySearchTeaserMiddle.vue';
import neoPrivacy from './privacy/privacy.vue';
import neoHome from './home/home.vue';
import neoAppLinks from './home/appLinks/appLinks.vue';
import neoImprint from './imprint/imprint.vue';
import neoServices from './services/services.vue';
import neoServiceItem from './services/serviceItem/serviceItem.vue';
import neoServiceItemHome from './services/serviceItemHome/serviceItemHome.vue';
import neoArticleTeaser from './home/articleTeaser/articleTeaser.vue';
import neoResponsiveImage from './responsiveImage/responsiveImage.vue';
import neoHealthNewsTeaser from './home/healthNewsTeaser/healthNewsTeaser.vue';
import neoOpeningImage from './home/openingImage/openingImage.vue';
import neoPharmacyArticle from './pharmacyArticle/pharmacyArticle.vue';
import neoAbout from './about/about.vue';
import neoSwiper from './swiper/swiper.vue';
import neoSwiperHome from './swiperHome/swiperHome.vue';
import neoSwiperHomeMiddle from './swiperHomeMiddle/swiperHomeMiddle.vue';
import neoSwiperAbout from './swiperAbout/swiperAbout.vue';
import neoSwiperServices from './swiperServices/swiperServices.vue';
import neoSwiperOffers from './swiperOffers/swiperOffers.vue';
import neoSwiperServicesMiddle from './swiperServicesMiddle/swiperServicesMiddle.vue';
import neoSwiperOffersMiddle from './swiperOffersMiddle/swiperOffersMiddle.vue';
import neoSwiperMagazine from './swiperMagazine/swiperMagazine.vue';
import neoSwiperMagazineMiddle from './swiperMagazineMiddle/swiperMagazineMiddle.vue';
import neoIcon from './icon/icon.vue';
import neoNewsArticle from './newsArticle/newsArticle.vue';
import neoMagazine from './magazine/magazine.vue';
import neoPreorderWidget from './preorderWidget/preorderWidget.vue';
import neoPreorderWidgetMiddle from './preorderWidgetMiddle/preorderWidgetMiddle.vue';
import neoYoutubeVideo from './youtubeVideo/youtubeVideo.vue';

const neoMobileNav = () => import(
    /* webpackChunkName: "mobile-nav" */
    './mobileNav/mobileNav.vue'
);
const neoShortButtons = () => import(
    /* webpackChunkName: "short-buttons" */
    './shortButtons/shortButtons.vue'
);
const neoPreorder = () => import(
    /* webpackChunkName: "preorder" */
    './preorder/preorder.vue'
);
const neoPharmacySearchForm = () => import(
    /* webpackChunkName: "pharmacy-search-form" */
    './pharmacySearch/pharmacySearchForm/pharmacySearchForm.vue')
;

export default {
    neoMobileNav,
    neoShortButtons,
    neoHeader,
    neoFooter,
    neoContact,
    neoMainNavi,
    neoOffers,
    neoOfferItem,
    neoOfferModal,
    neoOfferTeaser,
    neoOpeningHours,
    neoLogo,
    neoMetaNavi,
    neoConsentManagement,
    neoContactForm,
    neoContactData,
    neoContactMap,
    neoNaviTeaser,
    neoPharmacySearch,
    neoPharmacySearchForm,
    neoPharmacySearchTeaser,
    neoPharmacySearchTeaserMiddle,
    neoPrivacy,
    neoHome,
    neoAppLinks,
    neoImprint,
    neoServices,
    neoServiceItem,
    neoServiceItemHome,
    neoArticleTeaser,
    neoHealthNewsTeaser,
    neoOpeningImage,
    neoPharmacyArticle,
    neoAbout,
    neoPreorder,
    neoSwiper,
    neoSwiperHome,
    neoSwiperHomeMiddle,
    neoSwiperAbout,
    neoSwiperServices,
    neoSwiperOffers,
    neoSwiperOffersMiddle,
    neoSwiperServicesMiddle,
    neoSwiperMagazine,
    neoSwiperMagazineMiddle,
    neoIcon,
    neoNewsArticle,
    neoMagazine,
    neoPreorderWidget,
    neoPreorderWidgetMiddle,
    neoYoutubeVideo,
    neoResponsiveImage
};