import { eventHub, EVENT_NAMES } from '../../../services/eventService';

const modalGuidebookId = 'guidebook';
const modalPharmacySearchId = 'pharmacy-search';
const modalBranchSelectionId = 'branch-selection';

export default {
    data() {
        return {
            showGuidebookSubmenu: false,
            showPharmacySearchSubmenu: false,
            showPharmacyBranchSelection: false
        };
    },
    methods: {
        onGuidebookItemClick() {
            this.toggleItem(this.showGuidebookSubmenu, modalGuidebookId);
        },
        onPharmacySearchItemClick() {
            this.toggleItem(this.showPharmacySearchSubmenu, modalPharmacySearchId);
        },
        onPharmacyBranchSelectionClick() {
            this.toggleItem(this.showPharmacyBranchSelection, modalBranchSelectionId);
        },
        toggleItem(state, modalId) {
            eventHub.$emit(EVENT_NAMES[state
                ? 'MODAL_CLOSE'
                : 'MODAL_OPEN'
            ], { modalId, scrollIrrelevant: true });
        }
    },
    created() {
        eventHub.$on(EVENT_NAMES.MODAL_OPEN, (payload) => {
            this.showGuidebookSubmenu = (payload.modalId === modalGuidebookId);
            this.showPharmacySearchSubmenu = (payload.modalId === modalPharmacySearchId);
            this.showPharmacyBranchSelection = (payload.modalId === modalBranchSelectionId);
        });

        eventHub.$on(EVENT_NAMES.MODAL_CLOSE, (payload) => {
            this.showGuidebookSubmenu = (payload.modalId === modalGuidebookId)
                ? false
                : this.showGuidebookSubmenu;
            this.showPharmacySearchSubmenu = (payload.modalId === modalPharmacySearchId)
                ? false
                : this.showPharmacySearchSubmenu;
            this.showPharmacyBranchSelection = (payload.modalId === modalBranchSelectionId)
                ? false
                : this.showPharmacyBranchSelection;
        });
    }
};