<template>
    <div
        :class="['offer-item', { 'is-swiper': swiper }]"
        :data-modal-id="modalId"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        modalId: {
            type: String,
            required: true
        },
        swiper: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style src="./offerItem.scss" lang="scss"></style>