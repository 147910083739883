export default {
    props: {
        isDesktop: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            showPharmacySearchForm: false
        };
    },
    methods: {
        onTeaserClick () {
            this.showPharmacySearchForm = !this.showPharmacySearchForm;
        }
    }
};