import { eventHub, EVENT_NAMES } from './eventService';

export function trackChatWidget() {
    const chatWidget = document.querySelector('chat-widget');

    if (!chatWidget) {
        return;
    }

    const customerNumber = chatWidget.dataset.customerNumber;
    const observer = new MutationObserver(() => {
        document
            .querySelector('mea-shadow-root')
            .shadowRoot
            .addEventListener('click', ({ target }) => {
                const action = (() => {
                    if (target.classList.contains('mea-primary')) {
                        return 'MESSAGE_SENT';
                    } else if (
                        target.classList.contains('widget-button-icon-and-text')
                        && target.title === 'Chat'
                    ) {
                        return 'CHAT_OPENED';
                    }
                    return '';
                })();

                if (action) {
                    fetch('meaChatStatistics/entry',
                        {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                customerNumber,
                                action
                            })
                        })
                        .catch(console.error);
                }
            });
        observer.disconnect();
    });

    observer.observe(chatWidget, { childList: true });
}

const TRACKING_ID = 'GTM-PC8FDLQ';
const TRACKING_COOKIE_NAME = 'agp-tracking-consent';
const TRACKING_COOKIE_LIFETIME = 7776000;
const trackingCookieRegExp = new RegExp(`${TRACKING_COOKIE_NAME}=(?<value>[-a-z0-9]+);?`);

export function promptConsent() {
    eventHub.$emit(EVENT_NAMES.MODAL_OPEN, {
        modalId: 'consent-manager',
        disableBodyScroll: true,
        dontCorrectOffset: true,
        needsToBeTopmost: true
    });
}

function isTrackingConsentGiven() {
    const match = trackingCookieRegExp.exec(document.cookie);

    if (!match) {
        return null;
    }

    return JSON.parse(match.groups.value);
}

export function setTrackingCookie(value) {
    document.cookie = `${TRACKING_COOKIE_NAME}=${value}; Path=/; max-age=${TRACKING_COOKIE_LIFETIME}; samesite=strict;`;

    if (value) {
        initGoogleAnalytics();
    } else {
        // disable tracking in case the script has already been loaded
        // https://developers.google.com/analytics/devguides/collection/ga4/disable-analytics
        // FIXME theres still a call on the next page(un)load (a beacon or if we void navigator.sendBeacon() an img)
        if (window.dataLayer) {
            window.dataLayer.length = 0;
        }
        window[`ga-disable-${TRACKING_ID}`] = true;
    }
}

export function initializePageTracking(isCockpitPreview) {
    if (isCockpitPreview) {
        return;
    }

    const weMayTrack = isTrackingConsentGiven();

    if (weMayTrack === null) {
        document.addEventListener(
            'DOMContentLoaded',
            promptConsent,
            { once: true }
        );
    } else if (weMayTrack) {
        initGoogleAnalytics();
    }
}

function initGoogleAnalytics() {
    // dataLayer must be populated before including the ga script
    // https://developers.google.com/tag-manager/devguide#adding-data-layer-variables-to-a-page
    prepareDataLayer();
    initGTM();
}

function initGTM() {
    const gtmSrc = `https://www.googletagmanager.com/gtm.js?id=${TRACKING_ID}`;

    if (document.querySelector(`script[src="${gtmSrc}"]`)) {
        return;
    }

    const firstScript = document.getElementsByTagName('script')[0];
    const gtm = Object.assign(document.createElement('script'), {
        async: true,
        src: gtmSrc
    });

    firstScript.before(gtm);
}

function prepareDataLayer() {
    const data = window.location.pathname
        .slice(1)
        .split('/')
        .slice(0, 3)
        .reduce(
            (result, chapter, index) => ({
                ...result,
                [`chapter${index + 1}`]: chapter || 'Homepage'
            }),
            { portalname: window.location.origin }
        );
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data, {
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
}