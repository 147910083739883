<template>
    <swiper
        wrapper-class-name="swiper-magazine-middle"
        swiper-class-name="swiper-magazine-middle__container"
        :options="swiperOptions"
    >
        <slot
            :med="med"
            :sr="sr"
            :au="au"
            :buf="buf"
            :dr="dr"
            :preview="preview"
        />
    </swiper>
</template>

<script>
import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    methods: {
        preview({ target }) {
            if (
                target.classList.contains('swiper-magazine-middle__link')
                || target.classList.contains('swiper-magazine-middle__image')
            ) {
                document.getElementById(target.closest('.swiper-magazine-middle__slide').dataset.previewId).click();
            }
        }
    },
    data() {
        return {
            med: 'https://hvs.wortundbildverlag.de/cover/current/2d/small/mz.png',
            sr: 'https://hvs.wortundbildverlag.de/cover/current/2d/small/sr.png',
            au: 'https://hvs.wortundbildverlag.de/cover/current/2d/small/au.png',
            buf: 'https://hvs.wortundbildverlag.de/cover/current/2d/small/bf.png',
            dr: 'https://hvs.wortundbildverlag.de/cover/current/2d/small/dr.png',
            swiperOptions: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                autoHeight: true,
                spaceBetween: 20,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    1440: {
                        slidesPerView: 4,
                        slidesPerGroup: 1,
                    },
                }
            },
        };
    },
};
</script>

<style lang="scss" >
.swiper-magazine-middle {
    height: 100%;
    position: relative;

    &__container,
    &__wrapper {
        position: relative;
        height: 100%;
    }

    &-pagination {
        padding-top: 0;

        @media #{$desktop} {
            bottom: -$spacingXXXL;
        }
    }

    &__name {
        @include fontMixin(
            $colorLight,
            $fontSizeM,
            $fontWeightBold,
            $lineHeightS,
            $family: $fontFamily1
        );

        text-align: center;
        padding-top: 24px;
    }

    &__link {
        @include fontMixin(
            $colorRed,
            $fontSizeXS,
            $fontWeightBold,
            $lineHeightXS,
            $family: $fontFamily6
        );

        text-transform: uppercase;
        cursor: pointer;
        padding: 12px;
        text-align: center;
    }

    &__slide--inner {
        margin: 0 auto;
    }

    &__image-container {
        width: 185px;
        height: 247px;
        margin: 0 auto;
        cursor: pointer;

        img { width: 100%; }
    }

    .swiper-button--next {
        right: 0;
    }

    .swiper-button--prev {
        left: 0;
    }

    @media #{$tablet} {
        padding-top: 24px;
    }
}
</style>