<template>
    <div
        class="offers"
        @click="clickOnOffer"
    >
        <slot />
    </div>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../services/eventService';

export default {
    methods: {
        clickOnOffer({ target }) {
            if (!target.classList.contains('offer-item__details')) {
                return;
            }

            const offer = target.closest('[data-modal-id]');

            if (offer) {
                eventHub.$emit(EVENT_NAMES.MODAL_TOGGLE, { modalId: offer.dataset.modalId });
            }
        }
    }
};
</script>

<style src="./offers.scss" lang="scss"></style>