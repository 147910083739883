<template>
    <modal-overlay
        instance-class="consent-management"
        :id="id"
    >
        <div class="modal-overlay__content__item">
            <h1 class="heading1 custom-color-f">
                Einstellungen im Privacy Center
            </h1>
            <p>
                Wenn Sie uns Ihre Zustimmung geben, messen wir die Anzahl der Seitenaufrufe. Dies ermöglicht es uns zu erkennen, wie viele Personen unsere Webseite aufrufen.
            </p>
            <p>
                Für diese Analyse nutzen wir Google Analytics 4 mit der Standardmessung, alles andere ist deaktiviert. Die Nutzung der Daten zu eigenen Zwecken haben wir Google untersagt. Ihre IP-Adresse wird umgehend gelöscht.
            </p>
            <p>
                Mit dem Klick auf „Ich stimme zu“ stimmen Sie der Zählung der Seitenaufrufe durch den Dienstleister Google zu. In unserer Datenschutzerklärung finden Sie weitere Informationen zu dieser Datenverarbeitung sowie zum Widerruf Ihrer Einwilligung.
            </p>
            <div class="button-container">
                <button
                    class="button button--invert custom-color-b custom-border-color-b"
                    @click="dissent"
                >
                    Ablehnen
                </button>
                <button
                    class="button button--invert custom-color-b custom-border-color-b"
                    @click="consent"
                >
                    Ich stimme zu
                </button>
            </div>
        </div>
    </modal-overlay>
</template>

<script>
import ModalOverlay from '../modalOverlay/modalOverlay.vue';
import { setTrackingCookie } from '../../services/trackingService.js';

export default {
    name: 'ConsentManagement',
    components: { ModalOverlay },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    methods: {
        storeDecision(value, event){
            ModalOverlay.methods.closeModalClick.call(this, event);
            setTrackingCookie(value);
        },
        consent(event) {
            this.storeDecision(true, event);
        },
        dissent(event) {
            this.storeDecision(false, event);
        }
    }
};
</script>

<style lang="scss">
.consent-management {
    .modal-overlay__content__item {
        padding: 16px 24px;
        flex-direction: column;

        h1 {
            font-size: 1.6rem;
        }

        @media #{$tablet} {
            max-width: 60ch;
        }
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }
}
</style>