<template>
    <div
        class="footer-container"
        @click="maybeOpenPrivacyCenter"
    >
        <slot />
    </div>
</template>

<script>
import { promptConsent } from '../../services/trackingService.js';

export default {
    name: 'FooterComponent',
    methods: {
        maybeOpenPrivacyCenter({ target: { id } }) {
            if (id === 'consent-manager') {
                promptConsent();
            }
        }
    }
};
</script>

<style src="./footer.scss" lang="scss"></style>