/*
    To prevent the image-service from scaling images and
    thereby removing metadata,
    images are served with the /orig "scale", meaning they're unscaled.
    Those images aren't found locally, as they're not checked-in.
    To fix this, we try the available scales and look for the img in
    src/main/webapp/resources/images/[SCALE]
*/

function* imgScaleGenerator() {
    yield '/orig';
    yield '/400x';
    yield '/800x';
    yield '/1200x';
    yield '/1600x';
}

export default {
    data() {
        return {
            // TODO this is kinda wasteful as it goes thru all scales on ea switch.
            errorHandler: process.env.PRODUCTION_BUILD
                ? {}
                : { error: ({ target }) => {
                    if (target.src.includes('/orig')) {
                        this.tryToLocateImage(target);
                    }
                } }
        };
    },
    methods: {
        tryToLocateImageInDocument() {
            document.querySelectorAll('img[src*="/orig"]')
                .forEach(this.tryToLocateImage);
        },
        tryToLocateImage(img) {
            const imgScales = imgScaleGenerator();
            let currentImgScale = imgScales.next().value;
            let nextImgScale = imgScales.next().value;

            // the error-handler is called when an image-load fails
            img.onerror = () => {
                const next = imgScales.next();

                if (next.done) {
                    img.onerror = null;
                } else {
                    currentImgScale = nextImgScale;
                    nextImgScale = next.value;
                    this.replaceImgScale(img, currentImgScale, nextImgScale);
                }
            };

            // changing the img-src triggers an image-load
            this.replaceImgScale(img, currentImgScale, nextImgScale);
        },
        replaceImgScale(img, currentScale, nextScale) {
            img.src = img.src.replace(
                currentScale,
                nextScale
            );
        }
    }
};