<template>
    <swiper
        wrapper-class-name="swiper-offers-middle"
        swiper-class-name="swiper-offers-middle__container"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../services/eventService';
import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    data() {
        return {
            swiperOptions: {
                autoHeight: true,
                slidesPerView: 1,
                slidesPerGroup: 1,
                breakpoints: {
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    1140: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            }
        };
    },
    mounted() {
        document.querySelector('.swiper-offers-middle').addEventListener('click', this.clickOnOffer);
    },
    methods: {
        clickOnOffer({ target }) {
            const offer = target.closest('[data-modal-id]');

            if (offer) {
                eventHub.$emit(EVENT_NAMES.MODAL_TOGGLE, { modalId: offer.dataset.modalId });
            }
        }
    }
};
</script>