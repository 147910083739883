import { eventHub, EVENT_NAMES } from './eventService';

const CLASS_NAMES = {
    NO_SCROLL: 'no-scroll',
    NO_SCROLL_MOBILE: 'no-scroll-mobile'
};
const domService = {
    /**
     * Disabling page scrolling by setting certain classes on the body tag.
     * The body tag lies outside of the Vue app and therefore we need to do it through the document object.
     */
    disableBodyScroll() {
        document.body.classList.add(CLASS_NAMES.NO_SCROLL);
    },
    disableBodyScrollOnMobile() {
        document.body.classList.add(CLASS_NAMES.NO_SCROLL_MOBILE);
        document.ontouchmove = function(e) {
            e.preventDefault();
        };
    },
    enableBodyScroll() {
        document.body.classList.remove(CLASS_NAMES.NO_SCROLL);
        document.body.classList.remove(CLASS_NAMES.NO_SCROLL_MOBILE);
        document.ontouchmove = function() {
            return true;
        };
    }
};

export default function listenForModalToggle() {
    eventHub.$on(EVENT_NAMES.MODAL_OPEN, (payload) => {
        if (payload.disableBodyScroll) {
            domService.disableBodyScroll();
        } else if (payload.disableBodyScrollOnMobile) {
            domService.disableBodyScrollOnMobile();
        }
    });

    eventHub.$on(EVENT_NAMES.MODAL_CLOSE, ({ scrollIrrelevant }) => {
        if (!scrollIrrelevant) {
            domService.enableBodyScroll();
        }
    });

    if (document.body.classList.contains('full-theme')) {
        const header = document.querySelector('.header');

        eventHub.$on(EVENT_NAMES.MODAL_OPEN, (payload) => {
            // to fix the offset caused by the fixed header in the full theme,
            // we transform:translate(ed) the items after it, which caused their descendants
            // to take them as containing blocks, messing up their positioning.
            // removing the transform fixes that
            if (!payload.dontCorrectOffset) {
                header.classList.add('remove-transform');
            }

            // the header would otherwise overlay the modal
            if (payload.needsToBeTopmost) {
                header.classList.add('step-down');
            }
        });

        eventHub.$on(EVENT_NAMES.MODAL_CLOSE, () => {
            header.classList.remove('remove-transform');
            header.classList.remove('step-down');
        });
    }
}