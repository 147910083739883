var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pharmacy-search-teaser custom-border-color-b",
      class: { isInInfobox: _vm.isInInfobox },
    },
    [
      _vm._t("additionalLink", null, { onTeaserClick: _vm.onTeaserClick }),
      _vm._v(" "),
      _vm._t("default", null, {
        searchQueryMobile: _vm.searchQueryMobile,
        showHeader: _vm.showHeader,
        onTeaserClick: _vm.onTeaserClick,
        showPharmacySearchForm: _vm.showPharmacySearchForm,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }