<template>
    <swiper
        wrapper-class-name="swiper-services"
        swiper-class-name="swiper-services__container"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    data() {
        return {
            swiperOptions: {
                autoHeight: true,
                slidesPerView: 1,
                slidesPerGroup: 1,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                }
            }
        };
    }
};
</script>

<style lang="scss">
.swiper-services {
    position: relative;
    width: 100%;
    height: auto;
    padding: 45px 0;
    background-color: $colorLight;

    &__container,
    &__wrapper {
        height: 100%;
    }

    &__container {
        padding-bottom: 120px;
    }

    .swiper-pagination {
        bottom: 40px;
    }
}
</style>