var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-overlay",
    { attrs: { "instance-class": "offer-modal", id: _vm.id } },
    [
      _vm._t("default", null, {
        closeModalClick: _vm.closeModalClick,
        errorHandler: _vm.errorHandler,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }