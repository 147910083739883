var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-overlay",
    { attrs: { "instance-class": "consent-management", id: _vm.id } },
    [
      _c("div", { staticClass: "modal-overlay__content__item" }, [
        _c("h1", { staticClass: "heading1 custom-color-f" }, [
          _vm._v("\n            Einstellungen im Privacy Center\n        "),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Wenn Sie uns Ihre Zustimmung geben, messen wir die Anzahl der Seitenaufrufe. Dies ermöglicht es uns zu erkennen, wie viele Personen unsere Webseite aufrufen.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Für diese Analyse nutzen wir Google Analytics 4 mit der Standardmessung, alles andere ist deaktiviert. Die Nutzung der Daten zu eigenen Zwecken haben wir Google untersagt. Ihre IP-Adresse wird umgehend gelöscht.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Mit dem Klick auf „Ich stimme zu“ stimmen Sie der Zählung der Seitenaufrufe durch den Dienstleister Google zu. In unserer Datenschutzerklärung finden Sie weitere Informationen zu dieser Datenverarbeitung sowie zum Widerruf Ihrer Einwilligung.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass:
                "button button--invert custom-color-b custom-border-color-b",
              on: { click: _vm.dissent },
            },
            [_vm._v("\n                Ablehnen\n            ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "button button--invert custom-color-b custom-border-color-b",
              on: { click: _vm.consent },
            },
            [_vm._v("\n                Ich stimme zu\n            ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }