<template>
    <div :class="`${wrapperClassName}${isDisabled ? ' disabled' : ''}`">
        <slot />
        <div :class="`${generatedPaginationClass} swiper-pagination ${paginationClass}`" />
        <neo-icon
            name="arrow-right"
            :class="`${arrowNextClass} swiper-button swiper-button--next swiper-button__icon custom-color-b`"
        />
        <neo-icon
            name="arrow-left"
            :class="`${arrowPrevClass} swiper-button swiper-button--prev swiper-button__icon custom-color-b`"
        />
    </div>
</template>

<script>
import isCockpitPreview from '../../is-cockpit-preview.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { eventHub, EVENT_NAMES } from '../../services/eventService.js';
import neoIcon from '../../components/icon/icon.vue';

Swiper.use([Autoplay, Navigation, Pagination]);

export default {
    components: { neoIcon },
    data() {
        return {
            arrowNextClass: `${this.wrapperClassName}-button--next`,
            arrowPrevClass: `${this.wrapperClassName}-button--prev`,
            generatedPaginationClass: `${this.wrapperClassName}-pagination`,
            defaultOptions: {
                observer: true,
                observeParents: true,
                watchOverflow: true,
                speed: 1200,
                loop: true,
                direction: 'horizontal',
                centerInsufficientSlides: true,
                autoHeight: true,
                autoplay: {
                    delay: 5000
                },
                navigation: {
                    nextEl: `.${this.wrapperClassName}-button--next`,
                    prevEl: `.${this.wrapperClassName}-button--prev`,
                },
                pagination: {
                    el: `.${this.wrapperClassName}-pagination`,
                    type: 'bullets',
                    clickable: true,
                    bulletActiveClass: 'custom-background-color-b swiper-pagination__dot--active',
                    renderBullet: (_index, className) => {
                        return `<span class="${className} swiper-pagination__dot"></span>`;
                    },
                },
                threshold: 24
            },
            isDisabled: false
        };
    },
    props: {
        wrapperClassName: {
            type: String,
            default: ''
        },
        swiperClassName: {
            type: String,
            default: ''
        },
        paginationClass: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default: () => {},
            autoplay: {
                delay: Number
            },
            autoHeight: Boolean,
            speed: Number,
            slidesPerView: Number,
            pagination: {
                el: String,
                bulletActiveClass: String,
                type: String,
                clickable: Boolean,
                renderBullet: Function
            }
        }
    },
    mounted() {
        this.swiper = new Swiper(`.${this.swiperClassName}`, {
            ...this.defaultOptions,
            ...this.options
        });

        this.setSwiperLooping();

        // listen for updates to the op-images when in the cockpit preview
        if (isCockpitPreview && ['swiper-home__container', 'swiper-home-middle__container'].includes(this.swiperClassName)) {
            eventHub.$on(EVENT_NAMES.UPDATE_OP_IMG_SLIDER, (imgURLs) => {
                this.resetOpeningImageSlider(imgURLs);
            });
        }
    },
    methods: {
        // hide nav-buttons and prevent looping for single slide swipers
        setSwiperLooping() {
            this.isDisabled = this.$el.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length === 1;
            this.swiper.enabled = !this.isDisabled;
        },
        resetOpeningImageSlider(imgURLs) {
            this.swiper.removeAllSlides();
            this.swiper.appendSlide(imgURLs.map(imgURL => `<div class="swiper-slide">
                <img
                    class="opening-image__image swiper-slide-1-image iframe-image__image"
                    src="${imgURL}"
                />
            </div>`));
            this.setSwiperLooping();
        }
    }
};
</script>

<style lang="scss">
.swiper-button {
    display: none;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 32px);
    cursor: pointer;
    z-index: 10;

    &:focus {
        outline: none;
    }

    &--next {
        right: 8px;
    }

    &--prev {
        left: 8px;
    }

    &__icon {
        width: 32px;
        height: 32px;

        .disabled & {
            display: none;
        }
    }

    @media #{$tablet} {
        display: flex;
    }
}

.swiper-pagination {
    text-align: center;
    position: absolute;
    bottom: -40px;
    width: 100%;
    z-index: 1;

    &__dot {
        width: 12px;
        height: 12px;
        display: inline-block;
        margin: 8px;
        border-radius: 50%;

        &--active {
            cursor: initial !important;
        }

        &:focus {
            outline: none;
        }
    }

    &-bullet-active {
        background-color: $colorPrimary;
    }

    @media #{$tablet} {
        padding-top: 60px;
    }

    .disabled & {
        display: none;
    }
}
</style>