var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-navi" },
    [
      _vm._t("default", null, {
        onGuidebookItemClick: _vm.onGuidebookItemClick,
        onPharmacySearchItemClick: _vm.onPharmacySearchItemClick,
        onPharmacyBranchSelectionClick: _vm.onPharmacyBranchSelectionClick,
        showGuidebookSubmenu: _vm.showGuidebookSubmenu,
        showPharmacySearchSubmenu: _vm.showPharmacySearchSubmenu,
        showPharmacyBranchSelection: _vm.showPharmacyBranchSelection,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }