<template>
    <modal-overlay
        instance-class="offer-modal"
        :id="id"
    >
        <slot
            :closeModalClick="closeModalClick"
            :errorHandler="errorHandler"
        />
    </modal-overlay>
</template>

<script>
import tryToLocateImage from '../../../mixins/tryToLocateImage';
import ModalOverlay from '../../modalOverlay/modalOverlay.vue';

export default {
    name: 'OfferModal',
    mixins: [tryToLocateImage],
    components: { ModalOverlay },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    methods: {
        // we need the method-name in the scope, as it's used in the ftl-template, which is passed into the slot and
        // because we need this instances' id in the context of that method-call, we use Funtion.call()
        closeModalClick(event) {
            ModalOverlay.methods.closeModalClick.call(this, event);
        }
    }
};
</script>