<template>
    <div
        class="offer-teaser"
        @click="clickOnOffer"
    >
        <slot />
    </div>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../../services/eventService';

export default {
    methods: {
        clickOnOffer(evt) {
            if (!evt.target.classList.contains('offer-item__details')) {
                return;
            }

            evt.stopPropagation();

            const offer = evt.target.closest('[data-modal-id]');

            if (offer) {
                eventHub.$emit(EVENT_NAMES.MODAL_TOGGLE, { modalId: offer.dataset.modalId });
            }
        }
    }
};
</script>

<style lang="scss">
.offer-teaser {
    padding: $spacingXL 0;

    @media #{$tablet} {
        padding: $spacingXXL 0;
    }

    @media #{$desktop} {
        padding: $spacingXXXL 0;
    }
}
</style>