<template>
    <swiper
        wrapper-class-name="swiper-services-middle"
        swiper-class-name="swiper-services-middle__container"
        pagination-class="swiper-services-middle__pagination"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    data() {
        return {
            swiperOptions: {
                loopAdditionalSlides: 1,
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                }
            }
        };
    }
};
</script>

<style lang="scss">
.swiper-services-middle {
    width: 100%;
    height: auto;
    padding: 35px 0;
    background-color: $colorLight;

    &__wrapper {
        align-items: stretch;
    }

    &__container,
    &__wrapper {
        height: 100%;
    }

    &__container {
        padding-bottom: 134px;
    }

    &__pagination {
        bottom: 60px;
        position: relative;
        padding: 0;
    }

    @media #{$tablet} {
        position: relative;
        background-color: transparent;
        margin-top: $spacingXL;
        padding: 0 20px;

        &__container {
            padding-bottom: $spacingXXXL * 2;
        }

        &-button--next {
            right: -16px;
        }

        &-button--prev {
            left: -16px;
        }
    }

    @media #{$desktop} {
        padding: 0;
        margin: 0 auto;

        &-button--next {
            right: -40px;
        }

        &-button--prev {
            left: -40px;
        }
    }
}
</style>