import Vue from 'vue';

const eventHub = new Vue();
const EVENT_NAMES = {
    MODAL_TOGGLE: 'modal-toggle',
    MODAL_OPEN: 'modal-open',
    MODAL_CLOSE: 'modal-close',
    UPDATE_OP_IMG_SLIDER: 'UPDATE_OP_IMG_SLIDER',
    UPDATE_OP_IMG_SLIDER_FORMAT: 'UPDATE_OP_IMG_SLIDER_FORMAT',
    OPEN_ADVISOR: 'open-advisor'
};

export {
    eventHub,
    EVENT_NAMES
};