export {
    lazyLoadMagazinePreview,
    preventScrollInPreview
};

function lazyLoadMagazinePreview() {
    const target = document.querySelector('.home__content--magazine');
    if (!target) {
        return;
    }
    const previewJS = Object.assign(document.createElement('script'), {
        src: 'https://hvs.wortundbildverlag.de/preview/magazinePreview.js',
        async: true
    });
    previewJS.addEventListener('load', () => {
        window.document.dispatchEvent(new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true
        }));
    }, { once: true });

    new IntersectionObserver(([{ isIntersecting }], observer) => {
        if (isIntersecting) {
            document.body.append(previewJS);
            observer.disconnect();
        }
    }).observe(target);
}

function preventScrollInPreview() {
    // disable or enable scrolling when preview is opened or closed.
    // we need to infer this indirectly via the `fullScreen`-class as the preview itself is not in our hands
    const previewWrapper = document.getElementById('magazinePreviewWrapper');
    if (previewWrapper) {
        new MutationObserver(([{ target }]) => {
            document.body.classList[
                target.classList.contains('fullScreen') ? 'add' : 'remove'
            ]('no-scroll');
        }).observe(previewWrapper, {
            subtree: true,
            attributes: true,
            attributeFilter: ['class']
        });
    }
}