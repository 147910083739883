var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "img",
    _vm._g(
      _vm._b(
        {
          class: ["neo-responsive-image", _vm.classList],
          attrs: { "ci-ratio": _vm.aspectRatio, alt: "" },
        },
        "img",
        _vm._d({}, [_vm.srcAttributeName, _vm.imageSrc])
      ),
      _vm.errorHandler
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }