<template>
    <div
        class="privacy possibly-edited"
        @click="maybeOptOutOfTracking"
    >
        <slot />
    </div>
</template>

<script>
import { setTrackingCookie } from '../../services/trackingService';

export default {
    name: 'Privacy',
    methods: {
        maybeOptOutOfTracking({ target }) {
            if (target.id === 'opt-out-link') {
                setTrackingCookie(false);
            }
        }
    }
};
</script>

<style src="./privacy.scss" lang="scss"></style>