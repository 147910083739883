<template>
    <swiper
        wrapper-class-name="swiper-offers"
        swiper-class-name="swiper-offers__container"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../services/eventService';
import swiper from '../swiper/swiper.vue';

export default {
    components: { swiper },
    data() {
        return {
            swiperOptions: {
                autoHeight: true,
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 90,
                breakpoints: {
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    992: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    }
                }
            }
        };
    },
    mounted() {
        document.querySelector('.swiper-offers').addEventListener('click', this.clickOnOffer);
    },
    methods: {
        clickOnOffer(evt) {
            evt.stopPropagation();

            const offer = evt.target.closest('[data-modal-id]');

            if (offer) {
                eventHub.$emit(EVENT_NAMES.MODAL_TOGGLE, { modalId: offer.dataset.modalId });
            }
        }
    }
};
</script>

<style lang="scss">
.swiper-offers {
    position: relative;
    width: 100%;
    height: auto;

    &__container, &__wrapper {
        height: 100%;
    }

    &__container {
        padding-bottom: $spacingXL;
    }
}
</style>