var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "preorder-widget-middle",
        "custom-border-color-g",
        {
          "is-open": _vm.opened,
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "preorder-widget-middle__container" },
        [
          _vm.flavor === "list"
            ? _c("span", {
                staticClass: "preorder-widget-middle__icon-toggle",
                on: { click: _vm.toggleOpen },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "preorder-widget-middle__title" }, [
            _vm._v("\n            Unverbindliche Reservierung\n        "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "preorder-widget-middle__text" }, [
            _vm._v(
              "\n            Reservieren Sie jetzt Ihre Medikamente\n        "
            ),
          ]),
          _vm._v(" "),
          _vm.opened
            ? _c("p", { staticClass: "preorder-widget-middle__text" }, [
                _vm._v(
                  "\n            In welcher Filiale möchten Sie reservieren?\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }