<template>
    <swiper
        wrapper-class-name="swiper-home"
        swiper-class-name="swiper-home__container"
        :pagination-class="paginationClass"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import swiper from '../swiper/swiper.vue';
import aspectRatio from '../../mixins/aspectRatio';

export default {
    components: { swiper },
    mixins: [aspectRatio],
    data() {
        return {
            swiperOptions: {
                observer: true,
                observeParents: true,
                autoHeight: true,
                slidesPerView: 1,
            }
        };
    },
    computed: {
        paginationClass() {
            return this.isImageRatio16To9
                ? 'swiper-home__pagination--overlayed'
                : '';
        }
    }
};
</script>

<style lang="scss">
.swiper-home {
    margin-bottom: 40px;

    &.disabled {
        margin-bottom: 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__box {
        &.swiper-home__box--overlayed {
            bottom: 0;
        }

        &--inner {
            position: relative;
            margin: -28px 16px 0;
            padding: 22px 16px 16px 16px;
            color: $fontColorLight;
            opacity: 0.9;
            background-color: $colorPrimary;
            z-index: 1000;
        }
    }

    &__title {
        font-size: $fontSizeLplus;
        font-weight: $fontWeightBold;
        line-height: 1.25em;
        margin-bottom: $spacingM;
    }

    &__text {
        font-size: 20px;
    }

    &-pagination {
        position: relative;
        bottom: -13px;

        &--overlayed {
            position: relative;
            width: 100%;
            left: 0;
            bottom: 10px;
            z-index: 2000;
        }

        @media #{$tablet} {
            bottom: 30px;
        }
    }

    @media #{$tablet} {
        position: relative;

        &__title {
            font-size: $fontSizeXL;
            line-height: 38px;
        }

        &__text {
            font-size: 20px;
        }

        &__box {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;

            &--inner {
                position: absolute;
                opacity: 0.9;
                right: 0;
                bottom: 0;
                width: 400px;
                margin: 0;
                color: $fontColorLight;
                padding: 40px 40px 16px 40px;

                &-wrapper {
                    margin: auto;
                    min-height: 100px;
                    position: relative;
                    max-width: $contentMaxWidth;
                }
            }
        }

        &__pagination--overlayed {
            position: absolute;
            bottom: 36px;
        }
    }
}

.swiper-pagination-bullet {
    background: $colorGray7;
    opacity: 1;
    cursor: pointer;

    &-active {
        background-color: $colorPrimary;
    }
}

.swiper-pagination-bullet,
.swiper-pagination-bullets
.swiper-pagination-bullet.swiper-home__pagination-bullet {
    margin: 0 8px;
}

// Hard overwrite because of CSS specificity
.swiper-pagination,
.swiper-button-prev,
.swiper-button-next {
    .opening-image-single & {
        visibility: hidden;
    }
}
</style>