<template>
    <div
        :class="`modal ${instanceClass}`"
        v-if="isVisible"
    >
        <div class="modal__item">
            <slot />
        </div>
    </div>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../services/eventService';
import './modalOverlay.scss';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        instanceClass: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
        toggleModal() {
            eventHub.$emit(EVENT_NAMES.MODAL_OPEN, {
                modalId: this.id,
                disableBodyScroll: true,
                needsToBeTopmost: true
            });
        },
        closeModalClick(event) {
            event.stopPropagation();
            eventHub.$emit(EVENT_NAMES.MODAL_CLOSE, {
                modalId: this.id
            });
        }
    },
    created() {
        eventHub.$on(EVENT_NAMES.MODAL_TOGGLE, (payload) => {
            if (payload.modalId === this.id) {
                this.toggleModal();
            }
        });

        eventHub.$on(EVENT_NAMES.MODAL_OPEN, (payload) => {
            this.isVisible = payload.modalId === this.id;
        });

        eventHub.$on(EVENT_NAMES.MODAL_CLOSE, (payload) => {
            this.isVisible = payload.modalId === this.id
                ? false
                : this.isVisible;
        });
    }
};
</script>