import isCockpitPreview from '../is-cockpit-preview';
import { EVENT_NAMES, eventHub } from '../services/eventService';

const FORMAT_16_9 = '1.78';

export default {
    data() {
        return {
            isImageRatio16To9: false
        };
    },
    mounted() {
        this.isImageRatio16To9 = document.querySelector('.swiper-slide img')?.getAttribute('ci-ratio') === FORMAT_16_9;

        if (isCockpitPreview) {
            eventHub.$on(EVENT_NAMES.UPDATE_OP_IMG_SLIDER_FORMAT, (imageFormat) => {
                this.isImageRatio16To9 = imageFormat === '16:9';
            });
        }
    }
};