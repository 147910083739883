<template>
    <div class="contact-form">
        <slot
            :captchaModel="captchaModel"
            :contactModel="contactModel"
            :validationErrors="validationErrors"
            :onFormSubmit="onFormSubmit"
            :onRefreshCaptcha="onRefreshCaptcha"
            :onInput="onInput"
            :responseMessage="responseMessage"
            :showFormular="showFormular"
            :showValidationErrors="showValidationErrors"
        />
    </div>
</template>

<script>
const legalCharsRegExp = /^[a-zA-ZäÄöÖüÜß]*$/;
// thx to https://emailregex.com/
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const invalidFieldCssClass = 'form__item--error';
const validators = {
    required: (value) => value !== '',
    allowedChars: (value) => legalCharsRegExp.test(value),
    email: (value) => emailRegExp.test(value)
};

export default {
    data() {
        return {
            captcha: null,
            captchaId: null,
            captchaInput: null,
            captchaModel: {
                captchaId: '',
                captchaValue: ''
            },
            contactModel: {
                firstName: '',
                lastName: '',
                mail: '',
                phone: '',
                fax: '',
                message: ''
            },
            responseMessage: '',
            responseMessageContainer: null,
            showFormular: true,
            showValidationErrors: false,
            validationErrors: {
                firstName: false,
                lastName: false,
                mail: false,
                message: false,
                captchaValue: false
            },
            validations: {
                firstName: [validators.required, validators.allowedChars],
                lastName: [validators.required, validators.allowedChars],
                mail: [validators.required, validators.email],
                message: [validators.required],
                captchaValue: [validators.required]
            },
        };
    },
    mounted() {
        this.responseMessageContainer = document.querySelector('.contact-form__response-message');
        this.captcha = document.getElementById('captchaMedia');
        this.captchaId = document.getElementById('captchaId');
        this.captchaInput = document.getElementById('captcha-container').querySelector('.form__text-input');
    },
    methods: {
        async onRefreshCaptcha() {
            const response = await fetch('./captcha', {
                method: 'post'
            });

            if (response.ok) {
                const { captchaMedia, captchaId } = await response.json();

                this.captcha.src = captchaMedia;
                this.captchaId.value = captchaId;
                this.captchaModel.captchaId = captchaId;
            } else {
                this.scrollResponseMessageIntoView(
                    'Bedauerlicherweise gab es einen Fehler bei der Übertragung. Bitte versuchen Sie es später erneut.'
                );
            }
        },
        onInput({ target }) {
            const { name, value } = target;

            if (name in this.captchaModel) {
                this.captchaModel[name] = value;
            } else {
                this.contactModel[name] = value;
            }

            // input validation becomes active only after a failed submit
            if (this.showValidationErrors && this.validations[name] !== undefined) {
                const isFieldInvalid = this.validations[name].some((fn) => !fn(value));

                this.validationErrors[name] = isFieldInvalid;
                target.parentElement.classList[
                    isFieldInvalid
                        ? 'add'
                        : 'remove'
                ](invalidFieldCssClass);
            }
        },
        onFormSubmit({ target }) {
            let formIsValid = true;

            Object.entries(this.validations)
                .forEach(([fieldName, validations]) => {
                    const field = target.elements[fieldName];
                    const isFieldInvalid = validations.some((fn) => !fn(field.value));

                    this.validationErrors[fieldName] = isFieldInvalid;

                    if (isFieldInvalid) {
                        formIsValid = false;
                        this.showValidationErrors = true;
                        field.parentElement.classList.add(invalidFieldCssClass);
                    }
                });

            this.captchaModel.captchaId = target.elements.captchaId.value;

            // scroll first invalid field into view
            if (!formIsValid) {
                const firstInvalidField = document.querySelector(`.${invalidFieldCssClass}`);
                // if only the captcha is faulty, we want the user to actually see it wo scrolling
                const scrollToHere = firstInvalidField.id === 'captcha-input-wrapper'
                    ? this.captcha
                    : firstInvalidField;

                scrollToHere.scrollIntoView({ block: 'center' });

                return;
            }

            fetch('./Kontakt',
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            contact: Object.assign(this.contactModel, {
                                salutation: document.querySelector('input[name="salutation"]').value
                            }),
                            captcha: this.captchaModel
                        })
                })
                .then((response) => {
                    if (response.ok) {
                        this.showFormular = false;
                        this.scrollResponseMessageIntoView(
                            'Vielen Dank für Ihre Nachricht. Wir melden uns so schnell wie möglich.'
                        );
                    } else if (response.status === 409) {
                        // highlight captcha validation error
                        this.showValidationErrors = true;
                        this.validationErrors.captchaValue = true;
                        this.captchaInput.parentElement.classList.add(invalidFieldCssClass);
                        this.captcha.scrollIntoView({ block: 'center' });
                        // generate new captcha since captcha is one-try only and is expired after one failed guess
                        this.onRefreshCaptcha();
                    } else {
                        throw new Error('Something went wrong');
                    }
                })
                .catch(() => {
                    this.scrollResponseMessageIntoView(
                        'Bedauerlicherweise gab es einen Fehler bei der Übertragung. Bitte versuchen Sie es später erneut.'
                    );
                });
        },
        scrollResponseMessageIntoView(message) {
            this.responseMessage = message;
            this.$nextTick(() => this.responseMessageContainer.scrollIntoView({ block: 'center' }));
        }
    }
};
</script>

<style src="./contactForm.scss" lang="scss"></style>