<template>
    <swiper
        wrapper-class-name="swiper-home-middle"
        swiper-class-name="swiper-home-middle__container"
        :pagination-class="paginationClass"
        :options="swiperOptions"
    >
        <slot />
    </swiper>
</template>

<script>
import swiper from '../swiper/swiper.vue';
import aspectRatio from '../../mixins/aspectRatio';

export default {
    components: { swiper },
    mixins: [aspectRatio],
    data() {
        return {
            swiperOptions: {
                autoHeight: true,
                slidesPerView: 1,
            },
        };
    },
    computed: {
        paginationClass() {
            return this.isImageRatio16To9
                ? 'swiper-home-middle__pagination--overlayed'
                : '';
        }
    }
};
</script>