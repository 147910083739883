<template>
    <iframe
        width="757"
        height="424"
        style="max-width: 100%"
        :src="youtubeLink"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    />
</template>

<script>
export default {
    props: {
        youtubeVideoId: {
            type: String,
            required: true
        }
    },
    computed: {
        youtubeLink() {
            return `https://www.youtube.com/embed/${this.youtubeVideoId}`;
        }
    }
};
</script>