<template>
    <img
        v-on="errorHandler"
        :class="['neo-responsive-image', classList]"
        :[srcAttributeName]="imageSrc"
        :ci-ratio="aspectRatio"
        alt=""
    >
</template>

<script>
import tryToLocateImage from '../../mixins/tryToLocateImage';

/*
    If the image is responsive,
    we show a wide image on a wide viewport and
    a smaller one, on narrower viewports.
*/
export default {
    name: 'ResponsiveImage',
    mixins: [tryToLocateImage],
    props: {
        classList: {
            type: String,
            default: ''
        },
        isResponsive: {
            type: Boolean,
            default: false
        },
        defaultSrc: {
            type: String,
            default: ''
        },
        mobileSrc: {
            type: String,
            required: true
        },
        desktopSrc: {
            type: String,
            required: true
        },
        cdnImage: {
            type: Boolean,
            default: true
        },
        aspectRatio: {
            type: Number,
            default: 1.78
        }
    },
    data() {
        return {
            imageSrc: this.defaultSrc,
            mediaQuery: window.matchMedia('(min-width: 768px)'),
            srcAttributeName: this.cdnImage && process.env.PRODUCTION_BUILD
                ? 'ci-src'
                : 'src'
        };
    },
    mounted() {
        if (!this.isResponsive) {
            return;
        }

        this.setImageSrc(this.mediaQuery);

        // safari and early edge versions use `addListener`
        if ('addEventListener' in this.mediaQuery) {
            this.mediaQuery.addEventListener('change', this.setImageSrc);
        } else {
            this.mediaQuery.addListener(this.setImageSrc);
        }
    },
    methods: {
        setImageSrc({ matches: isDesktop }) {
            this.imageSrc = isDesktop
                ? this.desktopSrc
                : this.mobileSrc;
        }
    }
};
</script>