<template>
    <div class="navi-teaser">
        <slot />
    </div>
</template>

<script>
import { eventHub, EVENT_NAMES } from '../../../services/eventService';

export default {
    name: 'NaviTeaser',
    mounted() {
        eventHub.$emit(EVENT_NAMES.OPEN_ADVISOR, { target: this.$el });
    }
};
</script>

<style src="./naviTeaser.scss" lang="scss"></style>