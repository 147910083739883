export default {
    data: function() {
        return {
            isHidden: true,
            messageButton: 'ALLE ANZEIGEN'
        };
    },
    methods: {
        onShowServiceNameButtonClick() {
            this.isHidden = !this.isHidden;
            (this.isHidden) ? this.messageButton = 'ALLE ANZEIGEN' : this.messageButton = 'WENIGER ANZEIGEN';
        }
    }
};