var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon__wrapper", on: { click: _vm.clickHandler } },
    [
      _c("svg", { staticClass: "icon" }, [
        _c("use", { attrs: { "xlink:href": _vm.iconLink } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }