<template>
    <div
        class="contact__map"
        ref="container"
    />
</template>

<script>
import throttle from 'lodash.throttle';

export default {
    name: 'ContactMap',
    props: {
        hereMapApiKey: {
            type: String,
            default: undefined
        },
        lng: {
            type: String,
            required: true
        },
        lat: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            map: null
        };
    },
    mounted() {
        import(/* webpackChunkName: "here-maps-api" */ '@here/maps-api-for-javascript')
            .then(({ default: H }) => {
                const position = { lat: this.lat, lng: this.lng };
                const layers = new H.service.Platform({
                    apikey: this.hereMapApiKey
                }).createDefaultLayers();
                const map = new H.Map(
                    this.$refs.container,
                    layers.vector.normal.map,
                    {
                        pixelRatio: window.devicePixelRatio,
                        center: position,
                        zoom: 15,
                        imprint: null
                    }
                );
                const ui = new H.ui.UI(map, {
                    locale: 'de-DE',
                    scalebar: { alignment: 'bottom-right' },
                    zoom: { alignment: 'right-bottom' }
                } );
                const infoBubble = new H.ui.InfoBubble(
                    position,
                    { content: this.name }
                );

                window.addEventListener('resize', throttle(this.onResize, 700));
                infoBubble.addClass('contact__map__info-bubble');
                ui.addBubble(infoBubble);
                new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
                this.map = map;
            });
    },
    methods: {
        onResize() {
            this.map.getViewPort().resize();
        }
    }
};
</script>