var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pharmacy-search-teaser-middle custom-border-color-g" },
    [
      _vm._t("additionalLink", null, { onTeaserClick: _vm.onTeaserClick }),
      _vm._v(" "),
      _vm._t("default", null, {
        isDesktop: _vm.isDesktop,
        onTeaserClick: _vm.onTeaserClick,
        showPharmacySearchForm: _vm.showPharmacySearchForm,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }