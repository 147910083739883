import { eventHub, EVENT_NAMES } from '../../../services/eventService';
import isWithinInterval from 'date-fns/isWithinInterval';

const twoDigitsRegEx = /\d{2}/g;
const invalidValues = ['Geschlossen', ''];
// BE gives string of time-intervals, eg "00:00-00:01|..."
const transformDate = str => str
    // split string into array of intervals
    .split('|')
    // filter out intervals where pharmacy is closed
    .filter(s => invalidValues.indexOf(s) < 0)
    // split intervals into arrays of string-arrays where 1st entry is hour and 2nd minutes, eg [["00","00"],["00","01"]]
    .map(s => s
        .split('-')
        .map(t => t.match(twoDigitsRegEx)));
const isInOpening = (time) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();

    return isWithinInterval(now, {
        start: new Date(year, month, day, parseInt(time[0], 10), parseInt(time[1]), 10),
        end: new Date(year, month, day, parseInt(time[2], 10), parseInt(time[3]), 10)
    });
};

export default {
    props: {
        enableBodyScroll: {
            type: Boolean,
            default: false
        },
        displayIsOpen: {
            type: Boolean,
            default: false
        },
        modifier: {
            type: String,
            default: ''
        },
        opening: {
            type: String,
            default: ''
        },
        isBranch: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showOpeningHours: false,
            isOpen: false
        };
    },
    methods: {
        onOpeningHoursClick() {
            if (this.showOpeningHours) {
                eventHub.$emit(EVENT_NAMES.MODAL_CLOSE, {
                    modalId: this._uid
                });
            } else {
                eventHub.$emit(EVENT_NAMES.MODAL_OPEN, {
                    modalId: this._uid,
                    disableBodyScrollOnMobile: this.enableBodyScroll,
                    dontCorrectOffset: true
                });
            }
        },
        onClickOutsideOverlay({ target }) {
            if (this.showOpeningHours && !target.closest('.opening-hours')) {
                eventHub.$emit(EVENT_NAMES.MODAL_CLOSE, {
                    modalId: this._uid
                });
            }
        },
        calcIsOpen() {
            const isInRange = [];
            const [morning, afternoon] = transformDate(this.opening);

            if (!morning) {
                return;
            }

            if (Array.isArray(morning)) {
                const interval = morning.flat(Infinity);
                if (interval.length === 4) {
                    isInRange.push(isInOpening(interval));
                }
            }

            if (Array.isArray(afternoon)) {
                const interval = afternoon.flat(Infinity);
                if (interval.length === 4) {
                    isInRange.push(isInOpening(interval));
                }
            }

            this.isOpen = isInRange.some(Boolean);
        }
    },
    mounted() {
        if (this.displayIsOpen) {
            this.calcIsOpen();
        }

        eventHub.$on(EVENT_NAMES.MODAL_OPEN, (payload) => {
            if (payload.modalId === this._uid) {
                window.addEventListener('click', this.onClickOutsideOverlay);
                this.showOpeningHours = true;
            } else {
                window.removeEventListener('click', this.onClickOutsideOverlay);
                this.showOpeningHours = false;
            }
        });

        eventHub.$on(EVENT_NAMES.MODAL_CLOSE, (payload) => {
            if (payload.modalId === this._uid) {
                window.removeEventListener('click', this.onClickOutsideOverlay);
                this.showOpeningHours = false;
            }
        });
    },
    computed: {
        isSimple() {
            return this.modifier === 'simple';
        }
    }
};