export default {
    props: {
        name: {
            type: String,
            required: true
        },
        clickHandler: {
            type: [Function, null],
            default: () => null
        }
    },
    computed: {
        iconLink() {
            return `../../assets/icons/icon-sprite.svg#${this.name}`;
        }
    }
};